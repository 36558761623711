/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import { useIntl } from "react-intl";
import { usePageData } from "../../../../_prodev/layout/core";

const initialValues = {
  phone: '',
  password: '',
}

export function Login() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {showLoading} = usePageData()

  const loginSchema = Yup.object().shape({
    phone: Yup.string()
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}, {name: intl.formatMessage({id: 'PHONE'})}))
      .matches(
      /^\+[0-9\-().\s]{12}$/,
      intl.formatMessage({'id': 'LENGTH'}, {name: intl.formatMessage({'id': 'PHONE'}), len: 13})
    ),
    password: Yup.string()
      .min(4, intl.formatMessage({'id': 'MIN_LENGTH'}, {name: intl.formatMessage({'id': 'PASSWORD'}), len: 4}))
      .max(20, intl.formatMessage({'id': 'MIN_LENGTH'}, {name: intl.formatMessage({'id': 'PASSWORD'}), len: 20}))
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}, {name: intl.formatMessage({id: 'PASSWORD'})}))
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(() => {
        login(values.phone, values.password)
          .then(({data: {token}}) => {
            dispatch(auth.actions.login(token))
          })
          .catch(() => {
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'SIGN_IN_TO'}, {appName: 'InfoBozor'})}</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            {intl.formatMessage({id: 'SING_IN_USE_YOUR_LOGIN_AND_PASSWORD'})}
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'PHONE'})}
        </label>
        <input
          placeholder='Phone'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
          type='phone'
          name='phone'
          autoComplete='off'
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.phone}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'PASSWORD'})}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          placeholder={intl.formatMessage({id: 'PASSWORD'})}
          className={clsx('form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.password && formik.errors.password, },
            { 'is-valid': formik.touched.password && !formik.errors.password, }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!showLoading && <span className='indicator-label'>{intl.formatMessage({id: 'SIGN_IN'})}</span>}
          {showLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
}
