import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {AdminLayout} from '../../_prodev/layout/AdminLayout'
import {MainRoutes} from './MainRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import { UserModel } from "../modules/users/models/UserModel";

const Routes: FC = () => {
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel|null

  const switchUserRoutes = () => {
    if (['admin', 'moderator', 'agent'].includes(user?.status ?? '')) {
      return <AdminLayout><MainRoutes /></AdminLayout>
    } else {
      return <Redirect from='/auth' to='/' />
    }
  }

  return (
    <Switch>
      {/* Render auth page when user at `/auth` and not authorized. */}
      {/*Otherwise redirect to root page (`/`)*/}
      {!user ? <Route><AuthPage /></Route> : <Redirect from='/auth' to='/' />}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {/* Redirect to `/auth` when user is not authorized */}
      {!user ? <Redirect to='/' /> : switchUserRoutes()}
    </Switch>
  )
}

export {Routes}
