/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const initialValues = {
  fullName: '',
  phone: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
}

export function Registration() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const registrationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_LENGTH'}, {name: intl.formatMessage({id: 'FULL_NAME'}), len: 3}))
      .max(150, intl.formatMessage({id: 'MAX_LENGTH'}, {name: intl.formatMessage({id: 'FULL_NAME'}), len: 150}))
      .required(intl.formatMessage({id: 'REQUIRED'})),
    phone: Yup.string()
      .matches(
        /^\+[0-9\-().\s]{12}$/,
        intl.formatMessage({'id': 'LENGTH'}, {name: intl.formatMessage({'id': 'PHONE'}), len: 13})
      )
      .required(intl.formatMessage({id: 'REQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_LENGTH'}, {name: intl.formatMessage({id: 'PASSWORD'}), len: 4}))
      .max(150, intl.formatMessage({id: 'MAX_LENGTH'}, {name: intl.formatMessage({id: 'PASSWORD'}), len: 20}))
      .required(intl.formatMessage({id: 'REQUIRED'})),
    confirmPassword: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_LENGTH'}, {name: intl.formatMessage({id: 'PASSWORD'}), len: 4}))
      .max(150, intl.formatMessage({id: 'MAX_LENGTH'}, {name: intl.formatMessage({id: 'PASSWORD'}), len: 20}))
      .required(intl.formatMessage({id: 'REQUIRED'}))
      .when('password', {
        is: (val: string) => (!!(val && val.length > 0)),
        then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({id: 'PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH'})),
      }),
    acceptTerms: Yup.bool().required(intl.formatMessage({id: 'YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS'})),
  })

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        register(values.fullName, values.phone, values.password)
          .then(({data: {token}}) => {
            setLoading(false)
            dispatch(auth.actions.login(token))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus(intl.formatMessage({id: 'REGISTRATION_PROCESS_HAS_BROKEN'}))
          })
      }, 1000)
    },
  })

  return <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'CREATE_AN_ACCOUNT'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            {intl.formatMessage({id: 'SIGN_IN'})}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-12'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'FULL_NAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'FULL_NAME'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('fullName')}
            className={clsx('form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.fullName && formik.errors.fullName},
              {'is-valid': formik.touched.fullName && !formik.errors.fullName}
            )}
          />
          {formik.touched.fullName && formik.errors.fullName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.fullName}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Phone */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'PHONE'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'PHONE'})}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'PASSWORD'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx('form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {'is-valid': formik.touched.password && !formik.errors.password}
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'CONFIRM_PASSWORD'})}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'CONFIRM_PASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
            {'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword}
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            {intl.formatMessage({id: 'I_AGREE_THE'})}{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
}
